import * as React from 'react';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import FilePresentIcon from '@mui/icons-material/FilePresent';

function PdfList({docList, onUpdateDoc}) {
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
    onUpdateDoc(docList[index]);
  };

  return (
    <List component="nav" aria-label="main mailbox folders">
      {docList.map((title, index) => (
        <ListItemButton
          key={index}
          selected={selectedIndex === index}
          onClick={(event) => handleListItemClick(event, index)}
        >
          <ListItemIcon><FilePresentIcon /></ListItemIcon>
          <ListItemText
            primary={title}
            primaryTypographyProps={{variant: 'p', style: { fontSize: '0.9rem' }}}
          />
        </ListItemButton>
      ))}
    </List>
  );
}

export default PdfList;
