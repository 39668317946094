const MODAL_COOKIE_NAME = 'modal-shown';

function isCookiePresent(cookieName) {
  // Get all cookies
  const cookies = document.cookie.split(';');

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    // Check if cookie starts with "modal-shown="
    if (cookie.indexOf(cookieName) === 0) {
      return true;
    }
  }
  return false;
}

function setCookie(name, value) {
  document.cookie = `${name}=${value}`;
}

export {
  MODAL_COOKIE_NAME,
  isCookiePresent,
  setCookie
}
