import PdfList from './PdfList';

function NavPane({ docList, setDoc }) {
  return (
    <div className="nav-pane">
      <div className="nav-pdfs">
        <PdfList
          docList={docList}
          onUpdateDoc={setDoc}
        />
      </div>
    </div>
  );
}

export default NavPane;
