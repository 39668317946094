import { useState } from 'react';
import Grid from '@mui/material/Grid';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import Button from '@mui/material/Button';
import { styled, alpha } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';

import AboutDialog from './About';

import * as Cookies from '../utils/cookies';

const Search = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'primary',
  width: '100%',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    [theme.breakpoints.up('sm')]: {
      width: '25ch',
      '&:focus': {
        width: '25ch',
      },
    },
  },
}));

function ResponsiveAppBar({ setSearchTerm, navPane, drawerOpen, openDrawer, closeDrawer }) {
  const [visible, setVisible] = useState(
    !Cookies.isCookiePresent(Cookies.MODAL_COOKIE_NAME)
  );

  const openAbout = () => setVisible(true);
  const closeAbout = () => {
    Cookies.setCookie(Cookies.MODAL_COOKIE_NAME, 'true');
    setVisible(false);
  };

  return (
    <Box sx={{ flexGrow: 1, boxShadow: 0 }}>
      <AppBar position="static" color="transparent" sx={{ boxShadow: 0 }}>
        <Toolbar>
          <AboutDialog visible={visible} handleClose={closeAbout} />

          {/* Hamburger menu icon */}
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2, display: { sm: 'none' } }}
            onClick={openDrawer}
          >
            <MenuIcon />
          </IconButton>
          <Drawer
            anchor="left"
            open={drawerOpen}
            onClose={closeDrawer}
          >
            {navPane}
          </Drawer>

          <Grid container justifyContent="space-between" alignItems="center" sx={{ display: { xs: 'none', sm: 'flex' } }}>
            <Grid item xs={12} sm={3}>
              <div className="logo-nav">
                <img src="logo.png" alt="kuvendi" />
                <div className="logo-nav-text">
                  <h1>Republika e Shqipërisë</h1>
                  <h1>Kuvendi</h1>
                </div>
              </div>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Search>
                <div>
                  <SearchIconWrapper>
                    <SearchIcon />
                  </SearchIconWrapper>
                  <StyledInputBase
                    onChange={(e) => setSearchTerm(e.target.value)}
                    placeholder="Kerko ne kete dokument..."
                    inputProps={{ 'aria-label': 'search' }}
                  />
                </div>
              </Search>
            </Grid>

            <Grid item xs={12} sm={3} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button variant="text" onClick={openAbout}>
                <Typography variant="h6" noWrap sx={{ color: "#15438c" }}>
                  Rreth Nismes
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

export default ResponsiveAppBar;

