import {useRef, useEffect, useState} from 'react';
import WebViewer from '@pdftron/pdfjs-express-viewer';

import Fab from '@mui/material/Fab';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import DownloadingIcon from '@mui/icons-material/Downloading';

import HeightIcon from '@mui/icons-material/Height';
import FitScreenIcon from '@mui/icons-material/FitScreen';

function PdfViewer({filePath, searchTerm}) {
  const viewerRef = useRef(null);
  const [instance, setInstance] = useState(null);

  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const resizeHandler = () => setWidth(window.innerWidth);
    window.addEventListener('resize', resizeHandler)

    return () => window.removeEventListener('resize', resizeHandler)
  }, [])


  const FIT_MODES = {
    FitPage: {
      value: 'FitPage',
      icon: <HeightIcon />
    },
    FitWidth: {
      value: 'FitWidth',
      icon: <FitScreenIcon />
    }
  }
  const [pageFit, setPageFit] = useState(FIT_MODES.FitWidth);
  const cycleFitMode = () => {
    const modes = Object.values(FIT_MODES);
    const currentIndex = modes.findIndex(item => item.value === pageFit.value);
    const nextIndex = (currentIndex + 1) % modes.length;
    setPageFit(modes[nextIndex]);
  }

  const handleFitModeChange = () => {
    cycleFitMode();
    instance.UI.setFitMode(pageFit.value);
  }

  const handleZoomChange = (zoomDir) => {
    const maxZoom = instance.UI.getMaxZoomLevel();
    const minZoom = instance.UI.getMinZoomLevel();
    const zoom = instance.UI.getZoomLevel();

    if (zoomDir === 'in') {
      if (zoom < maxZoom) {
        instance.UI.setZoomLevel(zoom + 0.25);
      }
    } else {
      if (zoom > minZoom) {
        instance.UI.setZoomLevel(zoom - 0.25);
      } else {
        instance.UI.setZoomLevel(minZoom);
      }
    }
  }

  useEffect(() => {
    const opts = {
      path: 'pdfjsexpress',
      licenseKey: '9Dewk7Og6lUVwUble00h',
      disabledElements: [
        'viewControlsButton',
        'viewControlsOverlay'
      ]
    };

    WebViewer(opts, viewerRef.current).then(inst => {
      setInstance(inst);
      const { Core } = inst;
      inst.UI.disableElements(['header']);

      Core.documentViewer.addEventListener('documentLoaded', () => {
        inst.UI.setMaxZoomLevel('500%');
        inst.UI.setMinZoomLevel('25%');

        if (width < 600) {
          inst.UI.setFitMode('FitPage');
        } else {
          inst.UI.setZoomLevel('125%');
        }
      });
    })
  }, [width])

  useEffect(() => {
    if (instance && filePath) {
      instance.loadDocument(filePath);
    }
  }, [instance, filePath])

  useEffect(() => {
    if (!instance) return;

    if (searchTerm) {
      instance.searchText(searchTerm);
    } else {
      instance.UI.closeElements(['searchPanel']);
    }
  }, [instance, searchTerm])

  return (
    <>
    <div 
      ref={viewerRef}
      style={{height: "90vh"}}
    />

    <div className="pdf-controls">
      <Fab color="primary" aria-label="zoomIn" onClick={() => handleZoomChange('in')}>
        <ZoomInIcon />
      </Fab>

      <Fab color="primary" aria-label="zoomOut" onClick={() => handleZoomChange('out')}>
        <ZoomOutIcon />
      </Fab>

      <Fab color="primary" aria-label="fit" onClick={handleFitModeChange}>
        {pageFit.icon}
      </Fab>

      <Fab color="secondary" aria-label="download" onClick={() => instance.downloadPdf()}>
        <DownloadingIcon />
      </Fab>
    </div>
    </>
  )
}

export default PdfViewer;
