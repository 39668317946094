import { useEffect, useState } from 'react';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';

import ResponsiveAppBar from './components/ResponsiveAppBar';
import NavPane from './components/NavPane';
import PdfViewer from './components/PdfViewer';
import Loading from './components/Loading';

function App() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const toggleDrawer = (open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  const [searchTerm, setSearchTerm] = useState("")
  const [pdfDocs, setPdfDocs] = useState([])

  const [doc, setDoc] = useState(null)

  const updatePdf = (filename) => {
    setDoc(filename)
    setDrawerOpen(false)
  }

  useEffect(() => {
    fetch("/list-pdfs")
      .then(res => res.json())
      .then(({filenames}) => {
        setPdfDocs(filenames)
        setDoc(filenames[0])
      })
  }, [])

  if (pdfDocs.length === 0 || doc === null) {
    return <Loading />
  }

  const navPane = (
    <NavPane
      docList={pdfDocs}
      setDoc={updatePdf}
    />
  )

  return (
    <Container maxWidth="xl">
      <CssBaseline />
      <ResponsiveAppBar
        setSearchTerm={setSearchTerm}
        navPane={navPane}
        drawerOpen={drawerOpen}
        openDrawer={toggleDrawer(true)}
        closeDrawer={toggleDrawer(false)}
      />

      <Grid container spacing={2}>
        <Grid item xs={12} sm={3} sx={{ display: { xs: 'none', sm: 'block' } }}>
          {navPane}
        </Grid>

        <Grid item xs={12} sm={9}>
          <PdfViewer
            filePath={`/pdfs/${doc}.pdf`}
            searchTerm={searchTerm}
          />
        </Grid>
      </Grid>
    </Container>
  );
}

export default App
